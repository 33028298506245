@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Navigation Bar */

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vw;
  position: sticky;
  top: 0;
  z-index: 1000000;
  height: 5vh;
  border-bottom: 0.25px solid #ffffff;
  background-color: #ffffff;
}

#logo {
  font-weight: 700;
  font-size: 35px;
  text-decoration: none;
  color: #121212;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0;
  z-index: 1000000;
  height: inherit;
}

#logo-text {
  font-style: italic;
  transition: 0.3s ease;
  /*   -webkit-text-stroke: 1px #000000; */
}

#logo-text:hover {
  cursor: pointer;
  transform: scale(0.85);
  transition: 0.3s ease;
}

#menu-button {
  width: 35px;
  z-index: 2;
  padding-left: 15px;
  width: 50px;
}

#menu-button span {
  display: block;
  width: 100%;
}

.blank-span {
  height: 0.5vh;
  background-color: transparent;
}

.filled-span {
  height: 0.6vh;
  background-color: #121212;
  /* border: 1px solid #000000; */
}

#menu-button:hover {
  cursor: pointer;
  animation: wobble 0.4s;
}

.action {
  background-color: #004ff8;
  color: #ffffff;
  border-radius: 20px;
  padding: 8px 16px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 12px;
}

.action-medium {
  background-color: #004ff8;
  color: #ffffff;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
}

.hue-bar{
  box-shadow: 0 0 100px 40px rgb(0, 79, 248, 0.5);
}

.hue{
  box-shadow: 0 0 15px 8px rgb(0, 79, 248, 0.3);
}

.landing-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-center-inner{
  width: 55%;
}

.appt{
  font-size: 80px;
}

button:hover {
  cursor: pointer;
  filter: brightness(1.15);
}

.menu-right {
  display: flex;
  align-items: center;
}

#menu {
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 0.25px solid #999999;
}

#menu-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10vh 3vw;
  width: 100%;
}

.menu-item {
  color: black;
  text-decoration: none;
  font-size: 25px;
  width: 30%;
  padding: 1vh 0;
  margin: 1vh 0;
  border-bottom: 0.25px solid #999999;
  transition: 0.1s ease;
}

.menu-item:hover {
  color: #004ff8;
  font-weight: 600;
  transition: 0.1s ease;
}



/* Landing Page */

.title-outer-container {
  width: 100%;
  padding-left: 3vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-inner-container {
  width: 60%;
}

.site-title{
  font-size: 90px;
  font-weight: 600;
  /* background: -webkit-linear-gradient(left, #20BDFF, #004ff8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.blue-dot{
  color: #004ff8;
}

.italics{
  font-style: italic;
}

.underline{
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-color: #004ff8;
  text-decoration-thickness: 3px;
}

.inline-img{
  height: 1em;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.site-subtitle {
  font-size: 40px;
  line-height: 1.5em;
  font-weight: 400;
}

.site-subtitle-2{
  font-size: 25px;
  font-weight: 400;
}

.center-text{
  text-align: center;
}

/* About Section */

.about-section {
  width: 100%;
}

.about-text-inner-container {
  width: 100%;
}

.about-title {
  font-weight: 600;
  font-size: 2.5vw;
}

.about-text {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
  min-height: 38vh;
}

.about-description {
  font-weight: 400;
  font-size: 1.5vw;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35vh;
}



/* Service Section */

.video-landing{
  width: 100%;
  height: 80.25vh;
  background-color: #86868b;
}

.video-container{
  height: 80vh;
  overflow: hidden;
  position: relative;
}

.video-tag{
  width: 100%;
  position: absolute;
  bottom: -13vh;

}

.video-filter{
  width: 100%;
  height: 80vh;
  z-index: 1;
  background-color: #101010;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}

.video-text{
  color: #ffffff;
  z-index: 2;
  position: absolute;
  top: 30%;
  left: 3vw;
}

.video-title{
  font-size: 2.5vw;
}

.video-subtitle{
  font-size: 1.75vw;
  font-weight: 400;
}

.service-section {
  width: 100%;
  width: 100%;
  background-color: #000000;
  color: #f5f5f7;
  padding: 0;
}

.service-section-title{
  font-size: 2.5vw;
}

.service-section-subtitle{
  font-size: 1.75vw;
  color: #86868b;
}

.service-section-description{
  color: #86868b;
  font-weight: 500;
  font-size: 1.15vw;
}

.card{
  padding-bottom: 20vh;
}

.card-inner-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.card-title{
  padding: 3vw;
  font-size: 2vw;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img-container{
  width: 100%;
  height: 100%;
}

.card-text-header, .pill-text-header{
  font-weight: 600;
  color: #f5f5f7;
}

.card-text, .pill-description{
  color: #86868b;
  font-weight: 500;
  font-size: 1.15vw;
  line-height: 1.75em;
}

.service-box{
  padding: 3vw;
}

.pill{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
  background-color: #101010;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10vh;
}

.pill-text-container{
  padding: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.pill-img{
  width: 50%;
  height: 100%;
}

.center-container{
  width: 100%;
  display: flex;
  justify-content: center;
}

/* FAQ Section */
.faq-section-title{
  font-size: 2.5vw;
}

.faq-inner-container{
  display: flex;
  justify-content: space-between;
  height: 54vh;
}

.faq-list{
  width: 45%;
  overflow-y: scroll;
}

.faq{
  border-bottom: 0.25px solid #999999;
  padding: 10px;
  transition: height 2s linear;
}

.faq:hover{
  cursor: pointer;
}

.faq-top-line{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.faq-title{
  width: 90%;
}

.faq-button{
  padding: 3px;
  background-color: transparent;
  border: none;
  outline: none;
}

.faq-active{
  font-size: 1vw;
  font-weight: 600;
}

.faq-inactive{
  font-size: 1.5vw;
}

.faq-answer{
  width: 100%;
  padding-top: 10px;
}

.faq-img{
  background-image: url("./assets/faq.jpg");
  background-repeat: no-repeat;
  background-color: center;
  background-size: cover;
  border-radius: 10px;
  width: 45%;
}

/* Contact Section */
.contact-section{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact-img{
  background-image: url("./assets/handshake.jpg");
  background-repeat: no-repeat;
  background-color: center;
  background-size: cover;
  border-radius: 10px;
  width: 45%;
}

.contact-form{
  width: 45%;
}

.contact-title{
  font-size: 2.5vw;
}

.contact-form input{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 10px 15px;
  font-family: poppins;
  font-size: 0.9vw;
}

.contact-form textarea{
  width: 100%;
  height: 40vh;
  resize: none;
  overflow-y: scroll;
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 10px 15px;
  font-family: poppins;
  font-size: 0.9vw;
}

/* Footer */
.footer{
  padding: 5vh 0;
  width: 100%;
  border-top: 0.25px solid #999999;
  color: #86868b;
}

.footer p{
  padding-bottom: 5px;
}

.a-link{
  color: #8b8b8b;
}

.wrap-col{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
  width: 100%;
}

.wrap-col-child{
  width: 30%;
}

/* Animations and Effects */

.fade-in> :nth-child(1) {
  opacity: 0;
  animation: fadeIn 0.75s ease-in forwards;
}

.fade-in> :nth-child(2) {
  opacity: 0;
  animation: fadeIn 1s 0.15s ease-in forwards;
}

.fade-in> :nth-child(3) {
  opacity: 0;
  animation: fadeIn 1s 0.3s ease-in forwards;
}

.fade-in> :nth-child(4) {
  opacity: 0;
  animation: fadeIn 1s 0.35s ease-in forwards;
}

.fade-in> :nth-child(5) {
  opacity: 0;
  animation: fadeIn 1s 0.375s ease-in forwards;
}

.fade-in> :nth-child(6) {
  opacity: 0;
  animation: fadeIn 1s 0.38s ease-in forwards;
}

.fade-in> :nth-child(7) {
  opacity: 0;
  animation: fadeIn 1s 0.385s ease-in forwards;
}

.fade-in> :nth-child(8) {
  opacity: 0;
  animation: fadeIn 1s 0.3875s ease-in forwards;
}

.fade-in> :nth-child(9) {
  opacity: 0;
  animation: fadeIn 1s 0.39s ease-in forwards;
}

.fade-in> :nth-child(10) {
  opacity: 0;
  animation: fadeIn 1s 0.4s ease-in forwards;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-16px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(10deg);
  }

  50% {
    -webkit-transform: rotate(-10deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

/* Mobile Optimization */
@media (min-width: 320px) and (max-width: 999px) {
  #navbar{
    padding: 3vw;
    height: 8vh;
  }

  #menu-item-container{
    padding: 10vh 5vw;
  }

  .menu-item{
    width: 100%;
  }

  .landing-center-inner{
    width: 85%;
  }

  .site-title{
    font-size: 70px;
    line-height: 1em;
    padding-bottom: 3vh;
  }

  .site-subtitle{
    font-size: 25px;
  }

  .site-subtitle-2{
    font-size: 20px;
  }

  .about-title{
    font-size: 35px;
  }

  .about-text{
    width: 100%;
    display: block;
  }

  .about-description{
    display: block;
    height: auto;
    font-size: 17px;
    width: 100%;
  }

  .about-description p{
    padding-bottom: 15px;
  }

  .video-title{
    font-size: 35px;
  }

  .video-subtitle{
    font-size: 17px;
  }

  .action-medium{
    font-size: 17px;
  }

  .service-section-title{
    font-size: 35px;
  }

  .service-section-subtitle{
    font-size: 17px;
  }

  .wrap-col{
    display: block;
  }

  .wrap-col-child{
    width: 100%;
  }

  .card{
    padding-bottom: 10vh;
  }

  .card-title{
    padding-top: 20px;
    font-size: 30px;
  }

  .card-text{
    font-size: 17px;
    text-align: center;
    padding: 0 10px;
  }

  .pill{
    margin-top: 50px;
    display: block;
    min-height: 125vh;
    margin-bottom: 0;
  }

  .pill-img{
    width: 100%;
    height: 40%;
  }

  .pill-text-container{
    width: 100%;
    height: 60%;
  }

  .pill-text{
    width: 100%;
  }

  .pill-text-header{
    font-size: 20px;
  }

  .pill-description{
    font-size: 17px;
  }

  .faq-section-title{
    font-size: 35px;
    padding-bottom: 2vh;
  }

  .faq-inner-container{
    flex-direction: column-reverse;
    min-height: 100vh;
  }

  .faq-list{
    width: 100%;
    margin-top: 5vh;
  }

  .faq-button{
    font-size: 17px;
  }

  .faq-img{
    width: 100%;
    height: 50vh;
    padding-bottom: 5vh;
  }

  .faq-title{
    font-size: 17px;
  }

  .contact-section{
    flex-direction: column;
  }

  .contact-img{
    width: 100%;
    height: 50vh;
  }

  .contact-form{
    width: 100%;
    padding-top: 5vh;
  }

  .contact-title{
    font-size: 35px;
  }

  .contact-form input{
    font-size: 17px;
  }

  .contact-form textarea{
    font-size: 17px;
  }

  .appt{
    font-size: 40px;
  }
}